@font-face {
  font-family: "icons";
  src: url("assets/fonts/icons.eot?tjmbb3");
  src: url("assets/fonts/icons.eot?tjmbb3#iefix") format("embedded-opentype"),
    url("assets/fonts/icons.ttf?tjmbb3") format("truetype"),
    url("assets/fonts/icons.woff?tjmbb3") format("woff"),
    url("assets/fonts/icons.svg?tjmbb3#icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icons" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #fff;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-punto_esclamativo:before {
  content: "\e92c";
}
.icon-out:before {
  content: "\e92a";
}
.icon-upload:before {
  content: "\e92a";
}
.icon-load:before {
  content: "\e92a";
}
.icon-outbox:before {
  content: "\e92a";
}
.icon-warning-triangle:before {
  content: "\e930";
}
.icon-lock-open:before {
  content: "\e90f";
}
.icon-lock-open1:before {
  content: "\e912";
}
.icon-lock:before {
  content: "\e91c";
}
.icon-lock1:before {
  content: "\e91e";
}
.icon-checkmark:before {
  content: "\e920";
}
.icon-tick:before {
  content: "\e920";
}
.icon-re_edit:before {
  content: "\e900";
}
.icon-Backward-arrow-small:before {
  content: "\e901";
}
.icon-Forward-arrow-small:before {
  content: "\e902";
}
.icon-Up-arrow-small:before {
  content: "\e903";
}
.icon-Down-arrow-small:before {
  content: "\e904";
}
.icon-Down-arrow-small-thin:before {
  content: "\e905";
}
.icon-Backward-arrow:before {
  content: "\e906";
}
.icon-Down-arrow:before {
  content: "\e907";
}
.icon-Up-arrow:before {
  content: "\e908";
}
.icon-Forward-arrow:before {
  content: "\e909";
}
.icon-Arrow-rounded:before {
  content: "\e90a";
}
.icon-Close:before {
  content: "\e90b";
}
.icon-Add:before {
  content: "\e90c";
}
.icon-big_add:before {
  content: "\e90d";
}
.icon-Yes:before {
  content: "\e90e";
}
.icon-Alert:before {
  content: "\e910";
}
.icon-cestino:before {
  content: "\e911";
}
.icon-Clock:before {
  content: "\e913";
}
.icon-Comment:before {
  content: "\e914";
}
.icon-edit:before {
  content: "\e915";
}
.icon-Flag:before {
  content: "\e916";
}
.icon-Heart:before {
  content: "\e917";
}
.icon-info:before {
  content: "\e918";
}
.icon-Facebook:before {
  content: "\e919";
}
.icon-Instagram:before {
  content: "\e91a";
}
.icon-Twitter:before {
  content: "\e91b";
}
.icon-lock_open:before {
  content: "\e91d";
}
.icon-Menu:before {
  content: "\e91f";
}
.icon-Options:before {
  content: "\e921";
}
.icon-Profile:before {
  content: "\e922";
}
.icon-Quote:before {
  content: "\e923";
}
.icon-layers:before {
  content: "\e924";
}
.icon-folders:before {
  content: "\e925";
}
.icon-attachment:before {
  content: "\e926";
}
.icon-idea:before {
  content: "\e927";
}
.icon-group:before {
  content: "\e928";
}
.icon-brain:before {
  content: "\e929";
}
.icon-stats:before {
  content: "\e92b";
}
.icon-Avatar:before {
  content: "\e92d";
}
.icon-circle_info:before {
  content: "\e92e";
}
.icon-circle_Warning:before {
  content: "\e92f";
}
.icon-Search:before {
  content: "\e931";
}
.icon-settings:before {
  content: "\e932";
}
.icon-slider:before {
  content: "\e933";
}
.icon-pdf:before {
  content: "\e934";
}
.icon-grid-interface-line:before {
  content: "\e935";
}
.icon-grid-interface-four:before {
  content: "\e936";
}
.icon-grid-four-2:before {
  content: "\e937";
}
.icon-gird-nine:before {
  content: "\e938";
}
.icon-Web:before {
  content: "\e93a";
}
