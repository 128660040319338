html, body { 
  height: 100%; 
}

* {
    &:active,
    :focus {
      outline: none !important; // 1
    }
}  

label {
  margin-bottom: 0; // 2
}

a:not(.mat-button):not(.mat-raised-button):not(.mat-fab):not(.mat-mini-fab):not([mat-list-item]) {
  color: #7b55c8; // 3
}